import PerformanceParsing from "../data/performance/parsing";
import {getListTextPosition} from "../utils/common";
import {definePrize} from "../utils/prize";
import {applyAlign} from "./pattern-apply-align/pattern-apply-align";
import {getIsConditionResult} from "./pattern-condition/pattern-condition";
import {getRegistratorDataPattern} from "./pattern-data-registrator/pattern-data-registrator";
import {getUserFieldDataPattern} from "./pattern-data-user-field/pattern-data-user-field";




const PATTERN_REGEXP = /\[\[.+?\]\]/g;
const HE_REGEXP = /<he>(.*?)<\/he>/g;


const getCase = (director, caseType, name) => {
  if (!director) {
    return {surname: ``, lastname: ``, middlename: ``};
  }

  const peopleCase = director.aliases.find((alias) => alias.language === `ru_${caseType}`) || {surname: ``, lastname: ``, middlename: ``};
  const peopleCaseAuto = director.aliases.find((alias) => alias.language === `ru_${caseType}_auto`) || {surname: ``, lastname: ``, middlename: ``};

  return peopleCase[name] || peopleCaseAuto[name] || `&&`;
};

const addPeoplePattern = (obj, people, namePattern) => {
  obj[`[[${namePattern}_surname]]`] = people ? people.surname : ``;
  obj[`[[${namePattern}_surname_ru_r]]`] = getCase(people, `r`, `surname`);
  obj[`[[${namePattern}_surname_ru_d]]`] = getCase(people, `d`, `surname`);
  obj[`[[${namePattern}_surname_ru_t]]`] = getCase(people, `t`, `surname`);

  obj[`[[${namePattern}_lastname]]`] = people ? people.lastname : ``;
  obj[`[[${namePattern}_lastname_ru_r]]`] = getCase(people, `r`, `lastname`);
  obj[`[[${namePattern}_lastname_ru_d]]`] = getCase(people, `d`, `lastname`);
  obj[`[[${namePattern}_lastname_ru_t]]`] = getCase(people, `t`, `lastname`);

  obj[`[[${namePattern}_middlename]]`] = people ? people.middlename : ``;
  obj[`[[${namePattern}_middlename_ru_r]]`] = getCase(people, `r`, `middlename`);
  obj[`[[${namePattern}_middlename_ru_d]]`] = getCase(people, `d`, `middlename`);
  obj[`[[${namePattern}_middlename_ru_t]]`] = getCase(people, `t`, `middlename`);
};

const getDirectorsPattern = (data) => {
  if (!data.directors) {
    return {};
  }
  const directorsPattern = {};

  data.directors
  .filter((director) => director.peopleId !== `0`)
  .forEach((director, i) => {
    directorsPattern[`[[director_${i + 1}_title]]`] = getListTextPosition(director.positions);
    directorsPattern[`[[director_${i + 1}_person]]`] = director.person;

    addPeoplePattern(directorsPattern, director, `director_${i + 1}`);
  });

  return directorsPattern;
};

const getPeopleDirectorPattern = (data) => {
  const directorPattern = {};
  const director = data.people;

  if (!director) {
    return {};
  }

  if (director.peopleId === `0`) {
    return {};
  }

  directorPattern[`[[director_title]]`] = director && director.positions ? getListTextPosition(director.positions) : ``;
  directorPattern[`[[director_person]]`] = director ? director.person : ``;
  addPeoplePattern(directorPattern, director, `director`);

  return directorPattern;
};

const getPlayersPattern = (data) => {
  const playerPattern = {};

  if (!data.players) {
    return {};
  }

  data.players
  .filter((player) => player.peopleId !== `0`)
  .forEach((player, i) => {
    addPeoplePattern(playerPattern, player, `player_${i + 1}`);
  });

  return playerPattern;
};

const getPeoplePlayerPattern = (data) => {
  const playerPattern = {};
  const player = data.people;

  if (!player) {
    return {};
  }

  addPeoplePattern(playerPattern, player, `player`);
  return playerPattern;
};

const getCase2 = (team, caseType) => {
  if (!team) {
    return ``;
  }

  const peopleCase = team.aliases.find((alias) => alias.language === `ru_${caseType}`) || {title: ``};
  const peopleCaseAuto = team.aliases.find((alias) => alias.language === `ru_${caseType}_auto`) || {title: ``};

  return peopleCase.title || peopleCaseAuto.title || `&&`;
};

const getTeamPattern = (data) => {
  const pattern = {};
  const team = data.team;

  if (!team) {
    return {};
  }

  pattern[`[[team_title_ru_r]]`] = getCase2(team, `r`);
  pattern[`[[team_title_ru_d]]`] = getCase2(team, `d`);
  pattern[`[[team_title_ru_t]]`] = getCase2(team, `t`);

  return pattern;
};

const getOrganizationPattern = (data) => {
  const pattern = {};
  const organization = data.organization;
  if (!organization) {
    return {};
  }

  pattern[`[[organization_title_ru_r]]`] = getCase2(organization, `r`);
  pattern[`[[organization_title_ru_d]]`] = getCase2(organization, `d`);
  pattern[`[[organization_title_ru_t]]`] = getCase2(organization, `t`);

  return pattern;
};

const getTopNominationData = (nomination, nominations) => {
  if (!nomination) {
    return ``;
  }

  const nom = nominations.find((it) => it.nominationId === nomination.nominationId);

  if (!nom) {
    return ``;
  }

  const topId = nom.topId;

  if (topId === `0`) {
    // return nom.title;
    return ``;
  }
  const top = nominations.find((it) => it.nominationId === topId);

  if (!top) {
    return ``;
  }

  return top.title || ``;
};

const getParentNominationData = (nomination, nominations) => {
  if (!nomination) {
    return ``;
  }

  const nom = nominations.find((it) => it.nominationId === nomination.nominationId);

  if (!nom) {
    return ``;
  }

  const parentId = nom.parentId;

  if (parentId === `0`) {
    // return nom.title;
    return ``;
  }
  const parent = nominations.find((it) => it.nominationId === parentId);

  if (!parent) {
    return ``;
  }

  return parent.title || ``;
};

const getPrizeTitle = (data, prizes = []) => {
  if (data.prizeManualId) {
    const prize = definePrize(data.prizeManualId, prizes);
    return prize.title;
  }

  if (data.prizeId) {
    const prize = definePrize(data.prizeId, prizes);
    return prize.title;
  }

  return ``;
};

const getPerformancePlace = (data, ratingUserField) => {
  if (!ratingUserField) {
    return ``;
  }
  const value = data.userField[ratingUserField.userFieldId];
  return value ? value.join(`, `) : ``;
};

const getRefereeRating = (data) => {
  const ratings = data.ratings;

  if (!ratings) {
    return ``;
  }

  return ratings.map((rating) => rating.rating).join(` / `);
};

const getDataPattern = (data, prizes = [], ratingUserField, nominations = [], linkUserFields) => {
  const phonogramStartPoint = data.phonograms && data.phonograms.length > 0 ? data.phonograms[0].startPoint : null;
  const dataPattern = {
    [`[[performance_title]]`]: data.performanceTitle || ``,
    [`[[performance_start_point]]`] : phonogramStartPoint && phonogramStartPoint.name ? phonogramStartPoint.name : '',
    [`[[organization_title]]`]: data.organization ? data.organization.title : ``,
    [`[[performance_city]]`]: data.city || ``,
    [`[[performance_city_full]]`]: data.cityFull || ``,

    [`[[performance_country]]`]: data.country || ``,
    [`[[nomination_title]]`]: data.nomination ? data.nomination.title : ``,
    [`[[nomination_top_title]]`]: getTopNominationData(data.nomination, nominations),
    [`[[nomination_parent_title]]`]: getParentNominationData(data.nomination, nominations),
    [`[[age_category_title]]`]: data.ageCategory ? data.ageCategory.title : ``,

    [`[[performance_id]]`]: data.performanceId || ``,
    [`[[turn_id]]`]: data.turnId || ``,
    [`[[performance_cid]]`]: data.performanceCid || ``,
    [`[[player_count]]`]: String(data.countPlayers) || ``,
    [`[[prize_title]]`]: getPrizeTitle(data, prizes),
    [`[[performance_rating_sum]]`]: data.ratingSum,

    [`[[contest_title]]`]: data.contestTitle || data.title || ``,
    [`[[contest_rules_link]]`]: data.rulesLink || ``,
    [`[[privacy_policy_ptt]]`]: data.privacyPolicy || ``,
    [`[[performance_place]]`]: getPerformancePlace(data, ratingUserField),

    [`[[performance_city_type]]`]: data.cityType,
    [`[[performance_city_stype]]`]: data.cityShortType,

    [`[[team_city]]`]: data.team && data.team.isCityPrint ? data.team.city : ``,
    [`[[team_city_type]]`]: data.team ? data.team.cityType : ``,
    [`[[team_city_stype]]`]: data.team ? data.team.cityShortType : ``,

    [`[[organization_city]]`]: data.organization && data.organization.isCityPrint ? data.organization.city : ``,
    [`[[organization_city_type]]`]: data.organization ? data.organization.cityType : ``,
    [`[[organization_city_stype]]`]: data.organization ? data.organization.cityShortType : ``,
    [`[[performance_group_id]]`]: data.groupId,

    [`[[print_id]]`]: data.authId,
    [`[[referee_rating]]`]: getRefereeRating(data),

   };
   
 
  const registratorPattern = getRegistratorDataPattern(data);
  const userFieldPattern = getUserFieldDataPattern(data, linkUserFields);

  const directorsPattern = getDirectorsPattern(data);
  const directorPattern = getPeopleDirectorPattern(data);
  const playersPattern = getPlayersPattern(data);
  const playerPattern = getPeoplePlayerPattern(data);
  const teamPattern = getTeamPattern(data);
  const organizationPattern = getOrganizationPattern(data);

  return Object.assign(dataPattern, registratorPattern, userFieldPattern, directorsPattern, directorPattern, playersPattern, playerPattern, teamPattern, organizationPattern);
};


// <he>test [[performance_title]] [[team_title]]</he>
const applyHeLogic = (pattern, dataPattern) => {
  return pattern.replace(HE_REGEXP, (s, $1) => {
    // если внутри есть иф
    $1 = checkIfLogic($1, dataPattern);

    const matches = $1.match(PATTERN_REGEXP);

    if (!matches) {
      return ``;
      // return $1;
    }

    if (matches.every((match) => !dataPattern[match])) {
      return ``;
    }

    return $1.replace(PATTERN_REGEXP, (match) => dataPattern[match] || ``);
  });
};

// {/* <img src=https://sun9-49.userapi.com/impg/JYDmjC8FIAmIwm3GX4-HfmgjZXGwR1yW4L3Hpg/7XT_zDPjGAY.jpg?size=1527x2160&quality=96&sign=b7c9962ce9043d5a9aa7310dd853873d&type=album */}
// x=0 y=0 w=210 h=297 type=JPG>
const applyImage = (text) => {
  return text.replace(/<img.*?>/g, (s) => {
    const sourceMatch = s.match(/\bsrc=(.+?)\s/);
    const coordXMatch = s.match(/\bx=(\d+)\b/);
    const coordYMatch = s.match(/\by=(\d+)\b/);
    const widthMatch = s.match(/\bw=(\d+)\b/);
    const heightMatch = s.match(/\bh=(\d+)\b/);

    const source = sourceMatch ? sourceMatch[1] : ``;
    const coordX = coordXMatch ? coordXMatch[1] : `0`;
    const coordY = coordYMatch ? coordYMatch[1] : `0`;
    const width = widthMatch ? `${widthMatch[1]}mm` : `auto`;
    const height = heightMatch ? `${heightMatch[1]}mm` : `auto`;

    return `<img src="${source}" style="width: ${width}; height: ${height}; position: absolute; top: ${coordY}mm; left: ${coordX}mm; z-index: -1;">`;
  });
};


export default class PatternController {
  static getFormattedText(pattern, data, prizes = [], ratingUserField, nominations, linkUserFields) {
    const dataPattern = getDataPattern(data, prizes, ratingUserField, nominations, linkUserFields);

    pattern = pattern.replace(/<if/g, `∩`).replace(/<\/if>/g, `∪`);
    // pattern = pattern.replace(/<br>/g, `&br>`);

    pattern = applyAlign(pattern);
    pattern = applyImage(pattern);
    pattern = applyHeLogic(pattern, dataPattern);
    pattern = applyReplaceLogic(pattern, dataPattern);
    pattern = checkIfLogic(pattern, dataPattern);

    // pattern = pattern.replace(/&br>/g, `<br>`);

    return pattern.replace(PATTERN_REGEXP, (s) => dataPattern[s] || ``);
  }

  static getFormattedTextForScene(pattern, data, refereePattern) {
    let dataPattern = getDataPattern(data);

    dataPattern = Object.assign(dataPattern, refereePattern);

    pattern = pattern.replace(/<if/g, `∩`).replace(/<\/if>/g, `∪`);
    // pattern = pattern.replace(/<br>/g, `&br>`);

    pattern = applyAlign(pattern);
    pattern = applyImage(pattern);
    pattern = applyHeLogic(pattern, dataPattern);
    pattern = applyReplaceLogic(pattern, dataPattern);
    pattern = checkIfLogic(pattern, dataPattern);

    // pattern = pattern.replace(/&br>/g, `<br>`);

    return pattern.replace(PATTERN_REGEXP, (s) => dataPattern[s] || ``);
  }

  static getText(pattern, data, prizes = [], ratingUserField, nominations, linkUserFields) {
    const dataPattern = getDataPattern(data, prizes, ratingUserField, nominations, linkUserFields);

    pattern = applyHeLogic(pattern, dataPattern);
    // pattern = applyAlign(pattern);
    pattern = applyReplaceLogic(pattern, dataPattern);
    pattern = checkIfLogic(pattern, dataPattern);
    // pattern = applyIfLogic(pattern, dataPattern);

    return pattern.replace(PATTERN_REGEXP, (s) => dataPattern[s] || ``);
  }
}

const testIf = (data, condition, statement1, statement2 = ``, variableText) => {
  const variables = variableText.match(PATTERN_REGEXP) || [];

  // if (!variables) {
  //   return variableText;
  // }

  const isCondition = getIsConditionResult(condition, variables, data, variableText);

  if (isCondition) {
    return statement1;
  }

  return statement2;
};
// pattern = pattern.replace(/<if/g, `∩`).replace(/<\/if>/g, `∪`);

const NEW_IF_PATTERN = `∩=[^∩]*?>>.*?∪`;
// const NEW_IF_PATTERN = `<if=[^<][^i][^f]*>>.*?<\/if>`;

const checkIfLogic = (text, data) => {
  if (!new RegExp(NEW_IF_PATTERN).test(text)) {
    return text;
  }

  text = text.replace(new RegExp(NEW_IF_PATTERN, `gs`), (match) => {
    // match = равен конструкции ифа
    // debugger;
    return applyIfLogicNew(match, data);
  });

  return checkIfLogic(text, data);
};

const applyIfLogicNew = (text, data) => {
  return text.replace(new RegExp(`∩=(.*?)~~(.*?)(?:~~(.*?))?>>(.*?)∪`, `s`), (s, $1, $2, $3, $4) => {
    return testIf(data, $1, $2, $3, $4);
  });
};

const testReplace = (data, source, replaceTo, count = 1, variableText) => {
  variableText = variableText.replace(PATTERN_REGEXP, (s) => data[s] || ``);

  for (let index = 0; index < +count; index++) {
    variableText = variableText.replace(source, replaceTo);
  }

  return variableText;
};

// <replace=что заменить~~на что заменить~~сколько раз заменить>>где заменить</replace>
const applyReplaceLogic = (text, data) => {
  return text.replace(/<replace=(.*?)~~(.*?)(?:~~(.*?))?>>(.*?)<\/replace>/g, (s, $1, $2, $3, $4) => {
    return testReplace(data, $1, $2, $3, $4);
  });
};
